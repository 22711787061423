import React, { useEffect, useState } from "react";
import Header from "../../Components/Header";
import "../../assets/css/schoolies.css";
import ReactPlayer from "react-player/lazy";
import {
	schoolies1,
	schoolies2,
	schoolies3,
	schoolies4,
	crown,
	creator_bg,
	post_img,
	video1,
	video3,
	video4,
	video5,
	video2,
	thumb1,
	thumb2,
	thumb3,
	thumb4,
	thumb5,
	video6,
	thumb6,
} from "../../constant/index";
import Footer from "../../Components/Footer";
import { Link } from "react-router-dom";
import { schoolies_post } from "./data";
import { showLoader } from "../../constant/Loader";
import { headers, SchoolVideosUrl } from "../../Api helpers/ApiHelper";
import axios from "axios";

const Schoolies = () => {

	const [state, setState] = useState();

	const SchooliesApi = async () => {
		showLoader("flex");
		try {
			const { data } = await axios.get(SchoolVideosUrl, headers)
			console.log(data.response.data);
			setState(data.response.data);
			showLoader("none");
		} catch (error) {
			console.log(error);
			showLoader("none");
		}
	}

	useEffect(() => {
		SchooliesApi();
		window.scroll(0,0);
	}, [])

	const video_list = [
		{
			id: 1,
			video_url: video1,
		},
		{
			id: 2,
			video_url: video2,
		},
		{
			id: 3,
			video_url: video3,
		},
		{
			id: 4,
			video_url: video4,
		},
		{
			id: 5,
			video_url: video5,
		},
	];
	return (


		<>
			<Header />
			{/* schoolies sec wrapper */}
			<section className="schoolies-sec">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="schoolies-sec-content-wrapper">
								<div className="schoolies-sec-heading-img-wraper">
									<figure>
										<img className="img-fluid" src={creator_bg} />
									</figure>
								</div>
								<div className="schoolies-sec-conetent-wrapper">
									<h3>schoolies Videos</h3>
									<h3>ALL POSTS</h3>
								</div>
							</div>
							<div className="schoolies-wrapper">
								<div className="row">
									{state?.map((item, i) => {
										return (
											<div key={i} className="col-lg-6">
												<Link
													to={`/schoolies-post/${item?.id}`}
													state={{ data: item }}
												>
													<div
														className={
															"schoolies-card-wrapper " +
															"schoolies-card-" +
															i
														}
													>
														<div className="schoolies-img-wrapper">
															<figure>
																<ReactPlayer
																	light={item?.coverImageUrl}
																	url={item?.videoUrl}
																	className="react-player"
																	width="100%"
																	height="100%"
																/>
															</figure>
														</div>
														<div className="schoolies-content-wrapper">
															<div className="cat-wrapper">
																<h5 className="category invisible">
																	{/* {data?.category}{" "} */}
																	{/* <img className="crown" src={crown} /> */}
																</h5>
																<h5 className="date invisible">{item?.updatedAt.slice(0,10)}</h5>
															</div>
															<div className="school-name-wrapper">
																{/* <h4 className="name">{item?.title}</h4> */}
																<h4 className="name">{item?.title}</h4>
															</div>
														</div>
													</div>
												</Link>
											</div>
										)
									})}

									{/* {schoolies_post.map((data, index) => {
										return (
											<div key={index} className="col-lg-6">
												<Link
													to={`/SchooliesPost/${data?.id}`}
													state={{ data: data }}
												>
													<div
														className={
															"schoolies-card-wrapper " +
															"schoolies-card-" +
															index
														}
													>
														<div className="schoolies-img-wrapper">
															<figure>
																<ReactPlayer
																	light={data?.Featuedimg}
																	url={data?.video_url}
																	className="react-player"
																	width="100%"
																	height="100%"
																/>
															</figure>
														</div>
														<div className="schoolies-content-wrapper">
															<div className="cat-wrapper">
																<h5 className="category invisible">
																	{data?.category}{" "}
																	<img className="crown" src={crown} />
																</h5>
																<h5 className="date invisible">{data?.date}</h5>
															</div>
															<div className="school-name-wrapper">
																<h4 className="name">{data?.name}</h4>
															</div>
														</div>
													</div>
												</Link>
											</div>
										);
									})} */}
								</div>
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</section>
			{/* schoolies sec wrapper */}
		</>
	);
};

export default Schoolies;
