import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";
import { mobile_logo } from "../constant/index";

const Header = () => {
const navigate = useNavigate();
  useEffect(() => {
    const CurrentLocation = window.location.href;
    const menuItem = document.querySelectorAll("header .header-wrapper a");
    const menuLength = menuItem.length;
    for (let i = 0; i < menuLength; i++) {
      if (menuItem[i].href === CurrentLocation) {
        menuItem[i].className = "active";
      }
    }
    // Mobile Nav
    $("document").ready(function () {
      $("header .canvas-icon i").click(function () {
        $("header .mobile-header").addClass("show");
      });

      $("header .mobile-header .cancel").click(function () {
        $("header .mobile-header").removeClass("show");
      });
    });
    // Mobile Nav

  }, []);
  return (
    <>
      {/* Header starts here */}
      <header id="header">
        <div className="container">
          <div className="row">
            <div className="col-3 col-md-1 col-lg-1 align-self-center">
              <Link className="logo" to="/">
                <img src={mobile_logo} className="img-fluid" />
              </Link>
            </div>
            <div className="col-6 col-md-10 col-lg-10">
              <div className="header-wrapper">
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <Link to="/" className="nav-links">
                      HOME
                    </Link>
                  </li>
                  {/* <li className="nav-item">
                    <Link className="nav-links" to="/creator">
                      MEET THE CREATOR
                    </Link>
                  </li> */}
                  <li className="nav-item">
                    <Link className="nav-links" to="/about">
                      ABOUT US
                    </Link>
                  </li>

                  {/* <li className="nav-item">
                    <Link className="nav-links" to="/merch">
                      MERCH
                    </Link>
                  </li> */}
                  <li className="nav-item">
                    <Link className="nav-links" to="/schoolies">
                      Schoolies Videos
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-links" to="/field-trip">
                      FIELDTRIP TO Nirvanaverse
                    </Link>
                  </li>
                  <li className="nav-item">
                    <a className="nav-links"  target="_blank" href="https://demos-clients-websites.com/tankedgill/game">
                      Game
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-3 col-md-1 col-lg-1 align-self-center">
              <div className="canvas-icon text-white">
                <i className="fa fa-bars" aria-hidden="true"></i>
              </div>
            </div>
          </div>
        </div>
        <div className="mobile-header">
          <div className="cancel">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              fill="currentColor"
              className="bi bi-x"
              viewBox="0 0 16 16"
            >
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
          </div>
          <ul className="mobile-nav">
            <li className="nav-item">
              <Link to="/">Home</Link>
            </li>
            {/* <li className="nav-item">
              <Link to="/creator">MEET THE CREATOR</Link>
            </li> */}
            <li className="nav-item">
              <Link to="/about">ABOUT US</Link>
            </li>
            {/* <li className="nav-item">
              <Link to="/merch">MERCH</Link>
            </li> */}
            <li className="nav-item">
              <Link to="/schoolies"> Schoolies Videos</Link>
            </li>
            <li className="nav-item">
              <Link to="/field-trip">FIELDTRIP TO Nirvanaverse</Link>
            </li>
          </ul>
        </div>
      </header>
      {/* Header ends here */}
    </>
  );
};

export default Header;
