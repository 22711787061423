import React, { useRef, useState, useEffect } from "react";
import emailjs from "@emailjs/browser";
import Header from "../../Components/Header";
import "../../assets/css/FieldTrip.css";
import swal from "sweetalert";
import {
	fieldtrip_card_bg,
	notification,
	facebook,
	twitter,
	instagram,
	youtube,
	whatsapp,
} from "../../constant/index";
import { showLoader } from "../../constant/Loader";
import axios from "axios";
import { toast } from "react-toastify";
import { headers, NirvanaverseSignup } from "../../Api helpers/ApiHelper";
import { useNavigate } from "react-router-dom";


const FieldTrip = () => {
	const form = useRef();
	const navigate = useNavigate();
	const [name, setName] = useState(null);
	const [nirvanaversEmail, setNirvanaversEmail] = useState("");
	const [phoneNo, setPhoneNo] = useState("");
	const [discordId, setDiscordId] = useState("");
	// const sendEmail = (e) => {
	// 	e.preventDefault();
	// 	emailjs
	// 		.sendForm(
	// 			"service_zsthsy9",
	// 			"template_pe3xav8",
	// 			form.current,
	// 			"CNDO5NzprFummIJnN",
	// 		)
	// 		.then(
	// 			(result) => {
	// 				console.log(result, "ppppppppppppp");
	// 				swal(
	// 					"SUCCESS",
	// 					"You've successtully subscribed to our newsletter;",
	// 					"success",
	// 				);
	// 			},
	// 			(error) => {
	// 				console.log(error, "qqqqqqqqqqqqq");
	// 			},
	// 		);
	// };


	const SendNirvanaFunc = async (e) => {
		e.preventDefault();
		showLoader("flex");
		let datasend = {
			email: nirvanaversEmail,
			name: name,
			phone: phoneNo,
			discord_id: discordId,
		}
		try {
			const { data } = await axios.post(NirvanaverseSignup, datasend, headers)
			console.log(data);
			showLoader("none");
			setNirvanaversEmail("");
			navigate('/');
			setDiscordId("");
			setPhoneNo("");
			setName("");
			setNirvanaversEmail("");
			toast.success("You have successfully subscribed for newsletter!");
		} catch (error) {
			console.log(error);
			showLoader("none");
			setNirvanaversEmail("");
		}

		// function isValidEmail(email) {
		// 	return /\S+@\S+\.\S+/.test(email);
		// }
		// if (!isValidEmail(nirvanaversEmail)) {
		// 	toast.error("Email is invalid");
		// 	console.log('Email is invalid');
		// } else {
		// 	SubscribeEmail();
		// }
	}

	// const SubscribeEmail = async () => {

	// }

	useEffect(() => {
		window.scroll(0, 0);
	}, []);

	return (
		<>
			<Header />
			{/* About starts here */}
			<section className="about-page-sec sec-about">
				<div className="container">
					<div className="row">
						<div className="col-lg-1"></div>
						<div className="col-lg-10">
							<div className="about-card-wrapper">
								<div className="about-img-wrapper">
									<figure>
										<img src={fieldtrip_card_bg} className="img-fluid" />
									</figure>
								</div>
								<div className="about-content-wrapper">
									<h3>FIELDTRIP TO Nirvanaverse</h3>
									<h3 className="COMING">WE ARE COMING SOON</h3>
									<p>
										Sign up to be the first to know when the bus is ready to go
									</p>
									{/* <form ref={form} onSubmit={sendEmail}></form> */}
									{/* <form onSubmit={SendNirvaMail}> */}
									<form onSubmit={SendNirvanaFunc}>

										<div className="newsletter-wrapper">
											<div className="input-group">
												<div className="row">
													<div className="col-lg-6">
														<input
															required
															value={nirvanaversEmail}
															onChange={(e) => { setNirvanaversEmail(e.target.value) }}
															name="user_email"
															type="email"
															className="form-control"
															placeholder="Enter your email*"
														/>
													</div>
													<div className="col-lg-6">
														<input
															value={name}
															onChange={(e) => { setName(e.target.value) }}
															name="user_name"
															type="text"
															className="form-control"
															placeholder="Enter your name*"
														/>
													</div>
												</div>
												<div className="row">
													<div className="col-lg-6">
														<input value={phoneNo}
															onChange={(e) => { setPhoneNo(e.target.value) }}
															maxlength="15"
															name="user_phone"
															type="number"
															className="form-control"
															placeholder="Enter your phone no*" />

													</div>
													<div className="col-lg-6">
														<input value={discordId}
															required
															onChange={(e) => { setDiscordId(e.target.value) }}
															maxlength="30"
															name="user_discord"
															type="number"
															className="form-control"
															placeholder="Enter your discord id*" />
													</div>
												</div>

												<div className="w-100">
													<span className="input-group-btn">
														<button
															// value="Send"
															className="btn btn-theme"
															type="submit"
														>
															<img src={notification} />
															Notify Me
														</button>
													</span>
												</div>
											</div>
										</div>

									</form>

									{/* </form> */}
								</div>
							</div>
						</div>
						<div className="col-lg-1"></div>
					</div>
				</div>
				{/* follow sec wrapper starts here */}
				<section className="follow-wrapper">
					<div className="container">
						<div className="row">
							<div className="col-lg-3"></div>
							<div className="col-lg-6">
								<div className="follow-content-wrapper">
									<div className="follow-heading-wrapper">
										<h1>FOLLOW US ON:</h1>
									</div>
									<div className="follow-social-wrapper">
										<ul>
											<li>
												<a
													target="blank"
													href="https://www.facebook.com/Tanked-Gil-Schoolies-105441308857499"
												>
													<img src={facebook} />
												</a>
											</li>
											<li>
												<a
													target="blank"
													href="https://twitter.com/Tankedgilschool"
												>
													<img src={twitter} />
												</a>
											</li>
											<li>
												<a
													target="blank"
													href="https://www.instagram.com/tankedgilschoolies/"
												>
													<img src={instagram} />
												</a>
											</li>
											<li>
												<a
													target="blank"
													href="https://www.youtube.com/channel/UCyaQfplpA9XADvSfMaFGaEw"
												>
													<img src={youtube} />
												</a>
											</li>
											<li>
												<a href="#">
													<img src={whatsapp} />
												</a>
											</li>
										</ul>
									</div>
								</div>
							</div>
							<div className="col-lg-3"></div>
						</div>
					</div>
				</section>
				{/* follow sec wrapper ends here */}
			</section>
			{/* About ends here */}
		</>
	);
};

export default FieldTrip;
