import React from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import {
	cart,
	cart_img1,
	cart_img2,
	creator_bg,
	discount,
	updated,
} from "../../constant/index";
import "../../assets/css/cart.css";

const Cart = () => {
	return (
		<>
			<Header />
			<section className="cart-wrapper">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="cart-content-wrapper">
								<div className="cart-heading-img-wraper">
									<figure>
										<img src={creator_bg} />
									</figure>
								</div>
								<div className="cart-conetent-wrapper">
									<h3>my cart</h3>
									<h3>2x items</h3>
								</div>
							</div>
							<div className="cart-wrappers">
								<div className="row">
									<div className="col-lg-7">
										<div className="product-wrapper">
											<div className="product-card-wrapper">
												<div className="product-close">
													<button>
														<i className="fa fa-close"></i>
													</button>
												</div>
												<div className="product-img-wrapper">
													<figure>
														<img src={cart_img1} className="img-fluid" />
													</figure>
												</div>
												<div className="product-content-wrapper">
													<h4>TANKED SCHOOLIES</h4>
													<h5>
														SKU : <span>315487321321564798</span>{" "}
													</h5>
													<p>
														Lorem ipsum dolor sit amet, consectetur adipiscing
														elit, sed do eiusmod tempor incididunt ut labore et
													</p>
													<h6>
														Total : <span>$25.00</span>
													</h6>
												</div>
											</div>
											<div className="product-card-wrapper">
												<div className="product-close">
													<button>
														<i className="fa fa-close"></i>
													</button>
												</div>
												<div className="product-img-wrapper">
													<figure>
														<img src={cart_img2} className="img-fluid" />
													</figure>
												</div>
												<div className="product-content-wrapper">
													<h4>TANKED SCHOOLIES</h4>
													<h5>
														SKU : <span>315487321321564798</span>
													</h5>
													<p>
														Lorem ipsum dolor sit amet, consectetur adipiscing
														elit, sed do eiusmod tempor incididunt ut labore et
													</p>
													<h6>
														Total : <span>$25.00</span>
													</h6>
												</div>
											</div>
										</div>
										<div className="btn-wrapper">
											<button className="btn">
												{" "}
												<img src={updated} /> Update
											</button>
											<button className="btn">
												{" "}
												<img src={discount} /> Checkout
											</button>
										</div>
									</div>
									<div className="col-lg-5">
										<div className="order-summary-wrapper">
											<div className="order-content-wrapper">
												<h4>Order Summary</h4>
												<h5>
													Shipping : <span>$250</span>
												</h5>
												<h5>
													SUBTOTAL : <span>$250</span>
												</h5>
												<p>
													Taxes and discounts will be calculated at checkout
												</p>
											</div>
										</div>
										<div className="order-summary-wrapper promo-code">
											<div className="order-content-wrapper">
												<h5>ENTER PROMO CODE</h5>
												<p>If you have a promo code, please apply it below.</p>
												<div className="input-group">
													<input type="email" className="form-control" />
													<span className="input-group-btn">
														<button className="btn btn-theme" type="submit">
															Apply Code
														</button>
													</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</section>
		</>
	);
};

export default Cart;
