import React from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import {
	creator_bg,
	admin_img,
	crown,
	post_img,
	pin,
	sub,
	recent_post_img1,
	recent_post_img2,
} from "../../constant/index";
import ReactPlayer from "react-player/lazy";
import { useLocation, useParams } from "react-router-dom";
import { schoolies_post } from "./data";
import { useState } from "react";
import { useEffect } from "react";
import { base_url, headers, SiteSettingApi, SiteSettingApiUrl } from "../../Api helpers/ApiHelper";
import { showLoader } from "../../constant/Loader";
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton } from "react-share";
import axios from "axios";

const SchooliesPosts = () => {
	// const location = useLocation();
	const params = useParams();
	const [post, setPost] = useState(null);
	const [locationUrl, setLocationUrl] = useState(null);
	const [state, setState] = useState(null);
	const [siteSettings, setSiteSettings] = useState(null);

	const SingleSchoolApi = async () => {
		showLoader("flex");
		try {
			const { data } = await axios.get(`${base_url}/school-video-details/${params.id}`, headers)
			// console.log(data.response.data);
			setState(data.response.data);
			showLoader("none");
		} catch (error) {
			console.log(error);
			showLoader("none");
		}
	}

	// const siteSettingApi = async () => {
	// 	showLoader("flex");
	// 	try {
	// 		const { data } = await axios.get(SiteSettingApiUrl, headers)
	// 		console.log(data.response.data);
	// 		setSiteSettings(data.response.data);
	// 		showLoader("none");
	// 	} catch (error) {
	// 		console.log(error);
	// 		showLoader("none");
	// 	}
	// }

	useEffect(() => {
		SingleSchoolApi();
		// siteSettingApi();
		setLocationUrl(window.location.href);
		window.scroll(0, 0);
	}, []);

	console.log(locationUrl, 'locationzzzzzzzzzzzzzzzzzzzzz');

	return (
		<>
			<Header />
			<section className="schoolies-sec schoolies-posts">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="schoolies-sec-content-wrapper">
								<div className="schoolies-sec-heading-img-wraper">
									<figure>
										<img className="img-fluid" src={creator_bg} />
									</figure>
								</div>
								<div className="schoolies-sec-conetent-wrapper">
									<h3>schoolies Videos</h3>
									<h3>ALL POSTS</h3>
								</div>
							</div>
							<div className="schoolies-wrapper">
								<div className="row">
									<div className="col-lg-12">
										<div className="post-wrapper">
											<div className="post-content-wrapper">
												<div className="heading-wrapper">
													<img
														src={admin_img}
														className="img-fluid admin_img"
													/>{" "}
													<div className="main-heading-wrapper">
														<h4>{state?.title}</h4>
													</div>
												</div>
												<div className="post-img mt-5">
													<ReactPlayer
														playing
														controls
														url={state?.videoUrl}
														className="react-player"
														width="100%"
														height="100%"
													/>
												</div>
												<div className="social-icon-wrapper">
													<ul>
														<li>
															<FacebookShareButton
																url={`${locationUrl}`}
																quote={`${state?.title}`}
																// hashtag={"#hashtag"}
																description={`${state?.description}`}
																className="Demo__some-network__share-button"
															>
																<i
																	// onClick={()=>{window.open(`${siteSettings?.facebook}`)}} 
																	className="fa fa-facebook cursor_pointer"></i>
															</FacebookShareButton>
														</li>
														<li>
															<TwitterShareButton
																title={`${state?.title}`}
																url={`${locationUrl}`}
																hashtags={[`${state?.title}`]}
															>
																<i
																	// onClick={()=>{window.open(`${siteSettings?.twitter}`)}} 
																	className="fa fa-twitter cursor_pointer"></i>
															</TwitterShareButton>

														</li>
														<li>
															<a
																href={`https://www.linkedin.com/shareArticle?mini=true&url=${locationUrl}&title=${state?.title}&summary=${state?.description}`}
															>
																<i
																	// onClick={()=>{window.open(`${siteSettings?.linkedin}`)}} 
																	className="fa fa-linkedin cursor_pointer"></i>
															</a>
														</li>
														<li>
															<img src={pin} />
														</li>
													</ul>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</section>
			{/* schoolies sec wrapper */}
		</>
	);
};

export default SchooliesPosts;
