import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { headers, NewsLetterSubscribeUrl, SiteSettingApiUrl } from "../Api helpers/ApiHelper";
import {
	Drive_card_bg,
	facebook,
	instagram,
	twitter,
	whatsapp,
	youtube,
	subscription,
	discord,
} from "../constant/index";
import { showLoader } from "../constant/Loader";
import axios from "axios";
import $ from "jquery";


const Footer = () => {
	const [sendMail, setSendMail] = useState("");
	const [siteSettings, setSiteSettings] = useState(null);

	useEffect(() => {
		const CurrentLocation = window.location.href;
		const menuItem = document.querySelectorAll(".footer-wrapper li a");
		const menuLength = menuItem.length;
		for (let i = 0; i < menuLength; i++) {
			if (menuItem[i].href === CurrentLocation) {
				menuItem[i].className = "active";
			}
		}
	}, []);

	const redirectdiscord = () => {
		window.location.href = "https://discord.com/invite/QfxhapsMsM";
	};


	const siteSettingApi = async () => {
		showLoader("flex");
		try {
			const { data } = await axios.get(SiteSettingApiUrl, headers)
			console.log(data.response.data);
			setSiteSettings(data.response.data);
			showLoader("none");
		} catch (error) {
			console.log(error);
			showLoader("none");
		}
	}

	useEffect(() => {
		siteSettingApi();
	}, [])

	const SubmitEmail = (e) => {
		function isValidEmail(email) {
			return /\S+@\S+\.\S+/.test(email);
		}
		if (!isValidEmail(sendMail)) {
			toast.error("Email is invalid");
			console.log('Email is invalid');
		} else {
			SubscribeEmail();
		}
	}

	const SubscribeEmail = async () => {
		showLoader("flex");
		let datasend = {
			email: sendMail
		}
		try {
			const { data } = await axios.post(NewsLetterSubscribeUrl, datasend, headers)
			console.log(data);
			showLoader("none");
			$('#exampleModal').modal('hide');
			setSendMail("");
			toast.success("You have successfully subscribed for newsletter!");

		} catch (error) {
			console.log(error);
			showLoader("none");
			setSendMail("");
			$('#exampleModal').modal('hide');
		}
	}

	return (
		<>
			<footer id="footer">
				<div className="container">
					<div className="row">
						<div className="col-lg-12 pb-4">
							<div className="footer-card-wrapper">
								<div className="footer-img-wrapper">
									<figure>
										<img src={Drive_card_bg} className="img-fluid" />
									</figure>
								</div>
								<div className="footer-content-wrapper">
									<div className="footer-heading-wrapper">
										<h3>
											JOIN OUR COMMUNITY & <br /> GET EARLY ACCESS
										</h3>
									</div>
									<div className="footer-btn-wrapper">
										<button
											className="btn"
											type="button"
											data-toggle="modal"
											data-target="#exampleModal"
										>
											<img src={subscription} />
											<span>Subscribe</span>
										</button>
										<button onClick={redirectdiscord} className="btn">
											<img src={discord} />
											<span>Join Discord</span>
										</button>
									</div>
									<div className="footer-icon-wrapper">
										<ul>
											<li>
												<a
													target="blank"
													href={`${siteSettings?.facebook}`}
												>
													<img src={facebook} />
												</a>
											</li>
											<li>
												<a
													target="blank"
													href={`${siteSettings?.twitter}`}
												>
													<img src={twitter} />
												</a>
											</li>
											<li>
												<a
													target="blank"
													href={`${siteSettings?.instagram}`}
												>
													<img src={instagram} />
												</a>
											</li>
											<li>
												<a
													target="blank"
													href={`${siteSettings?.youtube}`}
												>
													<img src={youtube} />
												</a>
											</li>
											<li>

												<a target="blank"
													href={`https://api.whatsapp.com/send?phone=${siteSettings?.contactPhone}`}
													// href={`${siteSettings?.whatsapp}`}
												>
													<img src={whatsapp} />
												</a>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="row pt-5">
						<div className="col-lg-1"></div>
						<div className="col-lg-10">
							<div className="footer-wrapper">
								<ul className="navbar-nav">
									<li className="nav-item">
										<Link className="nav-links" to="/">
											HOME
										</Link>
									</li>
									<li className="nav-item">
										<Link className="nav-links" to="/about">
											ABOUT US
										</Link>
									</li>
									<li className="nav-item">
										<Link className="nav-links" to="/schoolies">
											Schoolies Videos
										</Link>
									</li>
									<li className="nav-item">
										<Link className="nav-links" to="/field-trip">
											FIELDTRIP TO Nirvanaverse
										</Link>
									</li>
								</ul>
							</div>
						</div>
						<div className="col-lg-1"></div>
					</div>
				</div>
			</footer>
			<div
				className="modal fade bd-example-modal-lg"
				id="exampleModal"
				tabindex="-1"
				role="dialog"
				aria-labelledby="exampleModalLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog" role="document">
					<div className="modal-content">
						<div className="modal-body">
							<div className="enter-email">
								<div className="email-heading-wrapper">
									<h2>Enter Your Email</h2>
									<p>
										Lorem ipsum dolor sit amet consectetur, adipisicing elit.
										Quidem ducimus, minima ad laboriosam dolorem obcaecati
									</p>

									<div className="input-group">
										<input
											required
											value={sendMail}
											onChange={(e) => { setSendMail(e.target.value) }}
											type="email"
											className="form-control"
											placeholder="Enter your email"
										/>
										<span >
											<button onClick={SubmitEmail} className="btn" type="submit">
												Subscribe Now
											</button>
										</span>
									</div>

								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Footer;
