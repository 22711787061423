import React, { useEffect, useState } from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import {
	banner_card_img,
	banner_left_img,
	banner_right_img,
	chair_img1,
	chair_img2,
	chair_img3,
	chair_img4,
	chair_img5,
	tanked_bg,
	bus_img,
	join_bg,
	Drive_card_bg,
	field_card_bg,
	video_img,
	video_btn,
	animated_card_bg,
	phase1,
	phase2,
	phase3,
	phase4,
	about_mobile,
	phase3_mobile,
	phase4_mobile,
	phase1_mobile,
	phase2_mobile,
	footer_mobile,
	field_card_bg_mobile,
	join_mobile,
	drive_mobile,
	field_mobile,
} from "../../constant/index";
import WOW from "wowjs";
import "animate.css";
import gsap from "gsap";
import $ from "jquery";
// import { toast } from "react-toastify";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { headers, HomePageUrl, RoadMapUrl } from "../../Api helpers/ApiHelper";
import { showLoader } from "../../constant/Loader";
import axios from "axios";

const Home = () => {

	const [state, setState] = useState();
	const [roadMap, setRoadMap] = useState();

	const HomePageApi = async () => {
		showLoader("flex");
		try {
			const { data } = await axios.get(HomePageUrl, headers)
			// console.log(data.response.data);
			setState(data.response.data);
			showLoader("none");
		} catch (error) {
			console.log(error);
			showLoader("none");
		}
	}

	const RoadMapApi = async () => {
		showLoader("flex");
		try {
			const { data } = await axios.get(RoadMapUrl, headers)
			console.log(data.response.data);
			setRoadMap(data.response.data);
			showLoader("none");
		} catch (error) {
			console.log(error);
			showLoader("none");
		}
	}

	useEffect(() => {
		HomePageApi();
		RoadMapApi();
	}, [])

	useEffect(()=>{
		window.scroll(0,0);
	})

	useEffect(() => {
		new WOW.WOW({
			live: false,
		}).init();
		gsap.fromTo(
			"#drop",
			{ x: 0, y: 0, ease: "power2.in" },
			{
				x: 0,
				y: "random( 100, 0 )",
				duration: 5,
				repeat: -1,
				repeatDelay: 0,
				yoyo: true,
				repeatRefresh: true,
			},
		);
		gsap.fromTo(
			"#drop2",
			{ x: 0, y: 0, ease: "power2.in" },
			{
				x: 0,
				y: "random( 100, 0 )",
				duration: 5,
				repeat: -1,
				repeatDelay: 0,
				yoyo: true,
				repeatRefresh: true,
			},
		);
		$(window).on("load resize scroll", function () {
			$(".bus_img_wrapper").each(function () {
				var windowTop = $(window).scrollTop();
				var elementTop = $(this).offset().top;
				var leftPosition = windowTop - elementTop;
				$(this).find(".bus-img").css({ right: leftPosition });
			});
		});
		if (window.innerWidth <= "480") {
			$(".tanked-card-wrapper img").attr("src", about_mobile);
			$(".roadmap .phase3 .phase-img-wrapper img").attr("src", phase3_mobile);
			$(".roadmap .phase4 .phase-img-wrapper img").attr("src", phase4_mobile);
			$(".join-card-wrapper img").attr("src", join_mobile);
			$(".roadmap .phase1 .phase-img-wrapper img").attr("src", phase1_mobile);
			$(".tanked-card-wrapper img").attr("src", about_mobile);
			$(".roadmap .phase2 .phase-img-wrapper img").attr("src", phase2_mobile);
			$(".roadmap .phase3 .phase-img-wrapper img").attr("src", phase3_mobile);
			$(".roadmap .phase4 .phase-img-wrapper img").attr("src", phase4_mobile);
			$(".footer-card-wrapper .footer-img-wrapper img").attr(
				"src",
				footer_mobile,
			);
			$(" .field-sec .field-card-wrapper img").attr(
				"src",
				field_card_bg_mobile,
			);
			$(".join-card-wrapper img").attr("src", join_mobile);
			$(".drive-card-wrapper img").attr("src", drive_mobile);
			$(".field-sec .field-card-wrapper img").attr("src", field_mobile);
			// $('.sec-about .about-card-wrapper img').attr("src",drive_mobile)
			$(".sec-about .about-card-wrapper .about-img-wrapper img").attr(
				"src",
				drive_mobile,
			);
		}
	}, []);



	return (
		<>
			<Header />
			<ToastContainer />
			{/* banner starts here */}
			<div className="banner-and-bubbles-wrapper">
				<section className="banner-sec">
					<div className="banner-left-img">
						<figure>
							<img src={banner_left_img} />
						</figure>
					</div>
					<div className="container-fluid">
						<div className="row">
							<div className="col-lg-3"></div>
							<div className="col-lg-6">
								<div className="banner-card-wrapper">
									<div className="banner-img-wrapper">
										<figure>
											<img src={banner_card_img} className="img-fluid" />
										</figure>
									</div>
									<div className="banner-content-wrapper">
										<h1 className="wow animate__animated animate__fadeInDown">
											TANKED GIL <br /> SCHOOLIES
										</h1>
									</div>
								</div>
							</div>
							<div className="col-lg-3"></div>
						</div>
					</div>
					<div className="chair-img-wrapper">
						<div className="chair-img-wrapper1">
							<figure>
								<img
									src={chair_img1}
									className="chair-img-1 wow animate__animated animate__fadeInDown"
								/>
							</figure>
						</div>
						<div className="chair-img-wrapper2">
							<figure>
								<img
									src={chair_img2}
									className="chair-img-2 wow animate__animated animate__fadeInDown"
								/>
							</figure>
						</div>
						<div className="chair-img-wrapper3">
							<figure>
								<img
									src={chair_img3}
									className="chair-img-3 wow animate__animated animate__fadeInDown"
								/>
							</figure>
						</div>
						<div className="chair-img-wrapper4">
							<figure>
								<img
									src={chair_img4}
									className="chair-img-4 wow animate__animated animate__fadeInDown"
								/>
							</figure>
						</div>
						<div className="chair-img-wrapper5">
							<figure>
								<img
									src={chair_img5}
									className="chair-img-5 wow animate__animated animate__fadeInDown"
								/>
							</figure>
						</div>
					</div>
					<div className="banner-right-img">
						<figure>
							<img src={banner_right_img} />
						</figure>
					</div>
				</section>
				<div className="bubbles">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						id="Layer_3"
						enableBackground="new 0 0 64 64"
						height="600"
						viewBox="0 0 64 64"
						width="200"
					>
						<g>
							<g id="drop">
								<circle cx="14" cy="10" fill="#10a8e4" r="8" />
								<path
									d="m19 9.999h-2c0-1.654-1.346-3-3-3v-1.999c2.757 0 5 2.242 5 4.999z"
									fill="#C5F0FF"
								/>
							</g>
							<g id="drop">
								<circle cx="32" cy="32" fill="#10a8e4" r="17" />
								<path
									d="m46 31.999h-2c0-6.617-5.383-12-12-12v-2c7.719.001 14 6.281 14 14z"
									fill="#C5F0FF"
								/>
							</g>
							<g id="drop">
								<circle cx="54" cy="54" fill="#10a8e4" r="8" />
								<path
									d="m58.999 53.999h-2c0-1.654-1.346-3-3-3v-2c2.757.001 5 2.243 5 5z"
									fill="#C5F0FF"
								/>
							</g>
							<circle cx="15" cy="58" fill="#10a8e4" r="4" id="drop" />
							<circle cx="6" cy="40" fill="#10a8e4" r="4" id="drop" />
							<g fill="#C5F0FF">
								<circle cx="9" cy="28" r="4" id="drop" />
								<circle cx="56" cy="35" r="3" id="drop" />
								<circle cx="35" cy="55" r="3" id="drop" />
								<circle cx="32" cy="8" r="2" id="drop" />
								<circle cx="14" cy="49" r="2" id="drop" />
							</g>
						</g>
					</svg>
				</div>
				<div className="bubbles2">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						id="Layer_3"
						enableBackground="new 0 0 64 64"
						height="600"
						viewBox="0 0 64 64"
						width="150"
					>
						<g>
							<g id="drop2">
								<circle cx="14" cy="10" fill="#1B3D51" r="8" />
								<path
									d="m19 9.999h-2c0-1.654-1.346-3-3-3v-1.999c2.757 0 5 2.242 5 4.999z"
									fill="#C5F0FF"
								/>
							</g>
							<g id="drop2">
								<circle cx="32" cy="32" fill="#1B3D51" r="17" />
								<path
									d="m46 31.999h-2c0-6.617-5.383-12-12-12v-2c7.719.001 14 6.281 14 14z"
									fill="#C5F0FF"
								/>
							</g>
							<g id="drop2">
								<circle cx="54" cy="54" fill="#1B3D51" r="8" />
								<path
									d="m58.999 53.999h-2c0-1.654-1.346-3-3-3v-2c2.757.001 5 2.243 5 5z"
									fill="#C5F0FF"
								/>
							</g>
							<circle cx="15" cy="58" fill="#1B3D51" r="4" id="drop2" />
							<circle cx="6" cy="40" fill="#1B3D51" r="4" id="drop2" />
							<g fill="#C5F0FF">
								<circle cx="9" cy="28" r="4" id="drop2" />
								<circle cx="56" cy="35" r="3" id="drop2" />
								<circle cx="35" cy="55" r="3" id="drop2" />
								<circle cx="32" cy="8" r="2" id="drop2" />
								<circle cx="14" cy="49" r="2" id="drop2" />
							</g>
						</g>
					</svg>
				</div>
			</div>
			{/* banner ends here */}
			{/* about sec starts here */}
			<section className="about-sec">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="tanked-card-wrapper">
								<div className="tank-img-wrapper">
									<figure>
										<img className="img-fluid" src={tanked_bg} />
									</figure>
								</div>
								<div className="tanked-content-wrapper">
									<h3 className="wow animate__animated animate__fadeInDown">
										{state?.headingOne}
									</h3>
									<h3 className="wow animate__animated animate__fadeInDown"></h3>
									<div className="wow animate__animated animate__fadeInDown mb-0" dangerouslySetInnerHTML={{ __html: state?.contentOne }}
									></div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="container-fluid">
					<div className="row pt-5 bus_img_wrapper">
						<div className="col-lg-12">
							<div className="img-wrapper">
								<figure>
									<img
										src={bus_img}
										className="img-fluid bus-img wow animate__animated animate__fadeInRight"
									/>
								</figure>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* about sec ends here */}
			{/* join sec starts here */}
			<section className="join-sec">
				<div className="container-fluid">
					<div className="row">
						<div className="col-lg-8">
							<div className="join-card-wrapper">
								<div className="join-img-wrapper">
									<figure>
										<img src={join_bg} className="img-fluid" />
									</figure>
								</div>
								<div className="join-content-wrapper wow animate__animated animate__fadeInDown">
									<h3>{state?.headingTwo}</h3>
									<div dangerouslySetInnerHTML={{ __html: state?.contentTwo }}></div>
								</div>
							</div>
						</div>
						<div className="col-lg-4"></div>
					</div>
				</div>
			</section>
			{/* join sec ends here */}
			{/* Drive sec starts here */}
			{/* Drive sec ends here */}
			{/* Drive sec starts here */}
			<section className="field-sec">
				<div className="container-fluid">
					<div className="row">
						<div className="col-lg-4"></div>
						<div className="col-lg-8">
							<div className="field-card-wrapper">
								<div className="field-img-wrapper">
									<figure>
										<img src={field_card_bg} className="img-fluid" />
									</figure>
								</div>
								<div className="field-content-wrapper wow animate__animated animate__fadeInDown">
									<h3>{state?.headingThree}</h3>
									<div dangerouslySetInnerHTML={{ __html: state?.contentThree }}></div>
								</div>
							</div>
						</div>
						{/* <div className="col-lg-2"></div> */}
					</div>
				</div>
			</section>
			{/* Drive sec ends here */}
			{/* road  map sec starts here */}
			<section className="roadmap">
				<div className="container">
					<div className="row">
						<div className="col-lg-4"></div>
						<div className="col-lg-4">
							<div className="roadmap-bor">
								<div className="roadmap-wrapper">
									<h3>ROADMAP</h3>
								</div>
							</div>
						</div>
						<div className="col-lg-4"></div>
					</div>
				</div>
				<div className="container-fluid">
					<div className="row pt-1">
						<div className="col-lg-3">
							<div className="phase-card-wrapper phase1">
								<div className="phase-img-wrapper">
									<figure>
										<img src={phase1} className="img-fluid" />
									</figure>
								</div>
								<div className="phase-content-wrapper wow animate__animated animate__fadeInDown">
									<h4>{roadMap?.[0]?.title}</h4>
									<div dangerouslySetInnerHTML={{ __html: roadMap?.[0]?.description }} ></div>
								</div>
							</div>
						</div>
						<div className="col-lg-3">
							<div className="phase-card-wrapper phase2">
								<div className="phase-img-wrapper">
									<figure>
										<img src={phase2} className="img-fluid" />
									</figure>
								</div>
								<div className="phase-content-wrapper wow animate__animated animate__fadeInDown">
									<h4>{roadMap?.[1]?.title}</h4>
									<div dangerouslySetInnerHTML={{ __html: roadMap?.[1]?.description }} ></div>
								</div>
							</div>
						</div>
						<div className="col-lg-3">
							<div className="phase-card-wrapper phase3">
								<div className="phase-img-wrapper">
									<figure>
										<img src={phase3} className="img-fluid" />
									</figure>
								</div>
								<div className="phase-content-wrapper wow animate__animated animate__fadeInDown">
									<h4>{roadMap?.[2]?.title}</h4>
									<div dangerouslySetInnerHTML={{ __html: roadMap?.[2]?.description }} ></div>
								</div>
							</div>
						</div>
						<div className="col-lg-3">
							<div className="phase-card-wrapper phase4">
								<div className="phase-img-wrapper">
									<figure>
										<img src={phase4} className="img-fluid" />
									</figure>
								</div>
								<div className="phase-content-wrapper wow animate__animated animate__fadeInDown">
									<h4>{roadMap?.[3]?.title}</h4>
									<div dangerouslySetInnerHTML={{ __html: roadMap?.[3]?.description }} ></div>
								</div>
							</div>
						</div>
					</div>
					<div className="row pt-3">
						<div className="col-lg-3">
							<div className="phase-card-wrapper phase1 phase5">
								<div className="phase-img-wrapper">
									<figure>
										<img src={phase1} className="img-fluid" />
									</figure>
								</div>
								<div className="phase-content-wrapper wow animate__animated animate__fadeInDown">
								<h4>{roadMap?.[4]?.title}</h4>
									<div  dangerouslySetInnerHTML={{__html: roadMap?.[4]?.description}} ></div>
								</div>
							</div>
						</div>
						<div className="col-lg-3">
							<div className="phase-card-wrapper phase2 phase6">
								<div className="phase-img-wrapper">
									<figure>
										<img src={phase2} className="img-fluid" />
									</figure>
								</div>
								<div className="phase-content-wrapper wow animate__animated animate__fadeInDown">
								<h4>{roadMap?.[5]?.title}</h4>
									<div  dangerouslySetInnerHTML={{__html: roadMap?.[5]?.description}} ></div>
								</div>
							</div>
						</div>
						<div className="col-lg-3">
							<div className="phase-card-wrapper phase3 phase7">
								<div className="phase-img-wrapper">
									<figure>
										<img src={phase3} className="img-fluid" />
									</figure>
								</div>
								<div className="phase-content-wrapper wow animate__animated animate__fadeInDown">
								<h4>{roadMap?.[6]?.title}</h4>
									<div  dangerouslySetInnerHTML={{__html: roadMap?.[6]?.description}} ></div>
								</div>
							</div>
						</div>
						<div className="col-lg-3">
							<div className="phase-card-wrapper phase4 phase8">
								<div className="phase-img-wrapper">
									<figure>
										<img src={phase4} className="img-fluid" />
									</figure>
								</div>
								<div className="phase-content-wrapper wow animate__animated animate__fadeInDown">
								<h4>{roadMap?.[7]?.title}</h4>
									<div  dangerouslySetInnerHTML={{__html: roadMap?.[7]?.description}} ></div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<Footer />
			{/* animated sec ends here */}
		</>
	);
};

export default Home;
