import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "../Pages/Home/Home";
import About from "../Pages/About/About";
import MeetTheCreator from "../Pages/MeetTheCreator/MeetTheCreator";
import Merch from "../Pages/Merch/Merch";
import Product_page from "../Pages/Merch/Product_page";
import Cart from "../Pages/Mycart/Cart";
import FieldTrip from "../Pages/FIELDTRIP/FieldTrip";
import Schoolies from "../Pages/SCHOOLIES/Schoolies";
import SchooliesPosts from "../Pages/SCHOOLIES/Schoolies-posts";
import Game from "../Pages/Game/Game";

const PublicRoutes = (props) => {
	const { unityContext } = props;
	return (
		<>
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<Home />} />
					<Route path="about" element={<About />} />
					<Route path="creator" element={<MeetTheCreator />} />
					<Route path="merch" element={<Merch />} />
					<Route path="product-page" element={<Product_page />} />
					<Route path="Cart" element={<Cart />} />
					<Route path="field-trip" element={<FieldTrip />} />
					<Route path="schoolies" element={<Schoolies />} />
					<Route path="schoolies-post/:id" element={<SchooliesPosts />} />
					{/* <Route path="/game" element={<Game unityContext={unityContext} />} /> */}
					<Route path="*" element={<Home />} />
				</Routes>
			</BrowserRouter>
		</>
	);
};

export default PublicRoutes;
