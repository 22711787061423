import React from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import {
	merch1,
	merch2,
	merch3,
	merch4,
	merch5,
	merch6,
	merch7,
	merch8,
	merch9,
	merch10,
	merch11,
	merch12,
	creator_bg,
} from "../../constant/index";
import "../../assets/css/merch.css";
import { Link } from "react-router-dom";

const Merch = () => {
	const merch_product = [
		{
			id: 1,
			merchname: "TANKED SCHOOLIES",
			merchprice: "25.00",
			merchimg: merch1,
			merchnew: false,
			bestseller: false,
		},
		{
			id: 2,
			merchname: "TANKED SCHOOLIES",
			merchprice: "25.00",
			merchimg: merch2,
			merchnew: false,
			bestseller: false,
		},
		{
			id: 3,
			merchname: "I AM A PRODUCT",
			merchprice: "25.00",
			merchimg: merch3,
			merchnew: false,
			bestseller: true,
		},
		{
			id: 4,
			merchname: "I AM A PRODUCT",
			merchprice: "25.00",
			merchimg: merch4,
			merchnew: false,
			bestseller: false,
		},
		{
			id: 5,
			merchname: "TANKED SCHOOLIES",
			merchprice: "25.00",
			merchimg: merch5,
			merchnew: false,
			bestseller: false,
		},
		{
			id: 6,
			merchname: "TANKED SCHOOLIES",
			merchprice: "25.00",
			merchimg: merch6,
			merchnew: true,
			bestseller: false,
		},
		{
			id: 7,
			merchname: "TANKED SCHOOLIES",
			merchprice: "25.00",
			merchimg: merch7,
			merchnew: false,
			bestseller: false,
		},
		{
			id: 8,
			merchname: "TANKED SCHOOLIES",
			merchprice: "25.00",
			merchimg: merch8,
			merchnew: false,
			bestseller: false,
		},
		{
			id: 9,
			merchname: "TANKED SCHOOLIES",
			merchprice: "25.00",
			merchimg: merch9,
			merchnew: false,
			bestseller: true,
		},
		{
			id: 10,
			merchname: "TANKED SCHOOLIES",
			merchprice: "25.00",
			merchimg: merch10,
			merchnew: false,
			bestseller: false,
		},
		{
			id: 11,
			merchname: "TANKED SCHOOLIES",
			merchprice: "25.00",
			merchimg: merch11,
			merchnew: true,
			bestseller: false,
		},
		{
			id: 12,
			merchname: "TANKED SCHOOLIES",
			merchprice: "25.00",
			merchimg: merch12,
			merchnew: false,
			bestseller: false,
		},
	];
	return (
		<>
			<Header />
			{/* merch starts here */}
			<section className="merch-sec">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="merch-content-wrapper">
								<div className="merch-heading-img-wraper">
									<figure>
										<img src={creator_bg} />
									</figure>
								</div>
								<div className="merch-conetent-wrapper">
									<h3>MERCH</h3>
									<h3>COMING SOON</h3>
								</div>
							</div>
							<div className="merch-wrapper">
								<div className="row">
									{merch_product.map((data, index) => {
										return (
											<div key={index} className="col-lg-3">
												<div className="merch-card-wrapper">
													<Link to="/product-page">
														<div className="merch-img-wrapper">
															<figure>
																<img
																	src={data.merchimg}
																	className="img-fluid"
																/>
															</figure>
															{data.bestseller ? (
																<div className="new-pro">
																	<h5>BEST SELLER</h5>
																</div>
															) : data.merchnew ? (
																<div className="new-pro">
																	<h5>NEW</h5>
																</div>
															) : (
																""
															)}
														</div>
														<div className="merch-price-wrapper">
															<h4 className="name">{data.merchname}</h4>
															<h4 className="price">${data.merchprice}</h4>
														</div>
													</Link>
												</div>
											</div>
										);
									})}
								</div>
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</section>
			{/* merch ends here */}
		</>
	);
};

export default Merch;
