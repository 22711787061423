import React, { useState } from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import {
	cart,
	main_pro_img,
	main_pro_img1,
	main_pro_img2,
	main_pro_img3,
	creator_bg,
	cart_img,
} from "../../constant/index";
// css starts here
import "../../assets/css/product.css";
// css ends here

const Product_page = () => {
	const handleincrement = () => {
		setcounter(counter + 1);
	};
	const handledecrement = () => {
		setcounter(counter - 1);
		if (counter <= 0) {
			setcounter(0);
		}
	};
	const [counter, setcounter] = useState(0);
	// cart page redirect
	const cartpage = () => {
		window.location.href = "Cart";
	};
	return (
		<>
			<Header />
			{/* product sec starts here */}
			<section className="product_page">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="product_page-content-wrapper">
								<div className="product_page-heading-img-wraper">
									<figure>
										<img src={creator_bg} />
									</figure>
								</div>
								<div className="product_page-conetent-wrapper">
									<h3>MERCH</h3>
									<h3>COMING SOON</h3>
								</div>
							</div>
							<div className="product-wrapper">
								<div className="row px-4">
									<div className="col-lg-6">
										<div className="product-img-wrapper">
											<div className="product-main-img">
												<figure>
													<img src={main_pro_img} className="img-fluid" />
												</figure>
											</div>
											<div className="pro-slider-img">
												<img src={main_pro_img1} className="img-fluid" />
												<img src={main_pro_img2} className="img-fluid" />
												<img src={main_pro_img3} className="img-fluid" />
											</div>
										</div>
									</div>
									<div className="col-lg-6">
										<div className="product-content-wrapper">
											<h3>TANKED SCHOOLIES</h3>
											<h5>
												SKU : <span>315487321321564798</span>
											</h5>
											<h4>$20.00</h4>
											<h6>Quick Overview</h6>
											<p>
												Lorem ipsum dolor sit amet, consectetur adipiscing elit,
												sed do eiusmod tempor incididunt ut labore et dolore
												magna aliqua. Interdum velit euismod in pellentesque
												massa placerat duis ultricies. Commodo ullamcorper a
												lacus vestibulum sed arcu non. Non consectetur a erat
												nam at lectus urna. Diam maecenas ultricies mi eget
												mauris pharetra. Viverra tellus
											</p>
										</div>
										<div className="product-quality-btn">
											<div className="counter-wrapper">
												<label>QUANTITY</label>
												<div className="counter-btn-wrapper">
													<button className="plus" onClick={handleincrement}>
														+
													</button>
													<input value={counter} />
													<button onClick={handledecrement} className="minus">
														-
													</button>
												</div>
											</div>
											<div className="btn-wrapper">
												<button
													className="btn"
													data-toggle="modal"
													data-target="#exampleModal"
												>
													<img src={cart} /> <span>Add To Cart</span>
												</button>
											</div>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-lg-12">
										<div className="tabs-wrapper">
											<ul className="nav nav-tabs" id="myTab" role="tablist">
												<li className="nav-item">
													<a
														className="nav-link active"
														id="home-tab"
														data-toggle="tab"
														href="#home"
														role="tab"
														aria-controls="home"
														aria-selected="true"
													>
														PRODUCT INFO
													</a>
												</li>
												<li className="nav-item">
													<a
														className="nav-link"
														id="profile-tab"
														data-toggle="tab"
														href="#profile"
														role="tab"
														aria-controls="profile"
														aria-selected="false"
													>
														RETURN & REFUND POLICY
													</a>
												</li>
												<li className="nav-item">
													<a
														className="nav-link"
														id="contact-tab"
														data-toggle="tab"
														href="#contact"
														role="tab"
														aria-controls="contact"
														aria-selected="false"
													>
														SHIPPING INFO
													</a>
												</li>
											</ul>
										</div>

										<div className="tab-content" id="myTabContent">
											<div
												className="tab-pane fade show active"
												id="home"
												role="tabpanel"
												aria-labelledby="home-tab"
											>
												<div className="tab-content-wrapper">
													<p>
														Lorem ipsum dolor sit amet, consectetur adipiscing
														elit, sed do eiusmod tempor incididunt ut labore et
														dolore magna aliqua. Interdum velit euismod in
														pellentesque massa placerat duis ultricies. Commodo
														ullamcorper a lacus vestibulum sed arcu non. Non
														consectetur a erat nam at lectus urna. Diam maecenas
														ultricies mi eget mauris pharetra. Viverra tellus in
														hac habitasse platea. Elit ullamcorper dignissim
														cras tincidunt. Adipiscing elit duis tristique
														sollicitudin nibh. Interdum velit euismod in
														pellentesque massa placerat duis ultricies lacus. Eu
														turpis egestas pretium aenean pharetra. Duis ut diam
														quam nulla. Leo urna molestie at elementum eu.
														Aliquam vestibulum morbi blandit cursus. Tortor
														condimentum lacinia quis vel. Enim neque volutpat ac
														tincidunt vitae semper quis lectus nulla. Viverra
														tellus in hac habitasse platea dictumst vestibulum.
														Et netus et malesuada fames ac turpis egestas. Ut
														tellus elementum sagittis vitae et leo duis ut diam.
														Non curabitur gravida arcu ac. Iaculis nunc sed
														augue lacus viverra vitae congue.
													</p>
													<p>
														Adipiscing elit pellentesque habitant morbi
														tristique. Orci porta non pulvinar neque. Et netus
														et malesuada fames ac turpis. Sollicitudin ac orci
														phasellus egestas. Massa placerat duis ultricies
														lacus sed. Aliquam nulla facilisi cras fermentum
														odio eu feugiat pretium. Velit euismod in
														pellentesque massa placerat duis ultricies lacus
														sed. Sed arcu non odio euismod lacinia at quis.
														Sagittis nisl rhoncus mattis rhoncus urna neque
														viverra justo nec. Sed turpis tincidunt id aliquet
														risus feugiat in. Ut tellus elementum sagittis vitae
														et. Sollicitudin aliquam ultrices sagittis orci a
														scelerisque.
													</p>
												</div>
											</div>
											<div
												className="tab-pane fade"
												id="profile"
												role="tabpanel"
												aria-labelledby="profile-tab"
											>
												<div className="tab-content-wrapper">
													<p>
														Lorem ipsum dolor sit amet, consectetur adipiscing
														elit, sed do eiusmod tempor incididunt ut labore et
														dolore magna aliqua. Interdum velit euismod in
														pellentesque massa placerat duis ultricies. Commodo
														ullamcorper a lacus vestibulum sed arcu non. Non
														consectetur a erat nam at lectus urna. Diam maecenas
														ultricies mi eget mauris pharetra. Viverra tellus in
														hac habitasse platea. Elit ullamcorper dignissim
														cras tincidunt. Adipiscing elit duis tristique
														sollicitudin nibh. Interdum velit euismod in
														pellentesque massa placerat duis ultricies lacus. Eu
														turpis egestas pretium aenean pharetra. Duis ut diam
														quam nulla. Leo urna molestie at elementum eu.
														Aliquam vestibulum morbi blandit cursus. Tortor
														condimentum lacinia quis vel. Enim neque volutpat ac
														tincidunt vitae semper quis lectus nulla. Viverra
														tellus in hac habitasse platea dictumst vestibulum.
														Et netus et malesuada fames ac turpis egestas. Ut
														tellus elementum sagittis vitae et leo duis ut diam.
														Non curabitur gravida arcu ac. Iaculis nunc sed
														augue lacus viverra vitae congue.
													</p>
													<p>
														Adipiscing elit pellentesque habitant morbi
														tristique. Orci porta non pulvinar neque. Et netus
														et malesuada fames ac turpis. Sollicitudin ac orci
														phasellus egestas. Massa placerat duis ultricies
														lacus sed. Aliquam nulla facilisi cras fermentum
														odio eu feugiat pretium. Velit euismod in
														pellentesque massa placerat duis ultricies lacus
														sed. Sed arcu non odio euismod lacinia at quis.
														Sagittis nisl rhoncus mattis rhoncus urna neque
														viverra justo nec. Sed turpis tincidunt id aliquet
														risus feugiat in. Ut tellus elementum sagittis vitae
														et. Sollicitudin aliquam ultrices sagittis orci a
														scelerisque.
													</p>
												</div>
											</div>
											<div
												className="tab-pane fade"
												id="contact"
												role="tabpanel"
												aria-labelledby="contact-tab"
											>
												<div className="tab-content-wrapper">
													<p>
														Lorem ipsum dolor sit amet, consectetur adipiscing
														elit, sed do eiusmod tempor incididunt ut labore et
														dolore magna aliqua. Interdum velit euismod in
														pellentesque massa placerat duis ultricies. Commodo
														ullamcorper a lacus vestibulum sed arcu non. Non
														consectetur a erat nam at lectus urna. Diam maecenas
														ultricies mi eget mauris pharetra. Viverra tellus in
														hac habitasse platea. Elit ullamcorper dignissim
														cras tincidunt. Adipiscing elit duis tristique
														sollicitudin nibh. Interdum velit euismod in
														pellentesque massa placerat duis ultricies lacus. Eu
														turpis egestas pretium aenean pharetra. Duis ut diam
														quam nulla. Leo urna molestie at elementum eu.
														Aliquam vestibulum morbi blandit cursus. Tortor
														condimentum lacinia quis vel. Enim neque volutpat ac
														tincidunt vitae semper quis lectus nulla. Viverra
														tellus in hac habitasse platea dictumst vestibulum.
														Et netus et malesuada fames ac turpis egestas. Ut
														tellus elementum sagittis vitae et leo duis ut diam.
														Non curabitur gravida arcu ac. Iaculis nunc sed
														augue lacus viverra vitae congue.
													</p>
													<p>
														Adipiscing elit pellentesque habitant morbi
														tristique. Orci porta non pulvinar neque. Et netus
														et malesuada fames ac turpis. Sollicitudin ac orci
														phasellus egestas. Massa placerat duis ultricies
														lacus sed. Aliquam nulla facilisi cras fermentum
														odio eu feugiat pretium. Velit euismod in
														pellentesque massa placerat duis ultricies lacus
														sed. Sed arcu non odio euismod lacinia at quis.
														Sagittis nisl rhoncus mattis rhoncus urna neque
														viverra justo nec. Sed turpis tincidunt id aliquet
														risus feugiat in. Ut tellus elementum sagittis vitae
														et. Sollicitudin aliquam ultrices sagittis orci a
														scelerisque.
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</section>
			{/* product sec ends here */}
			{/* <!-- Modal --> */}
			<div
				className="modal cart_modal fade"
				id="exampleModal"
				tabIndex="-1"
				role="dialog"
				aria-labelledby="exampleModalLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog" role="document">
					<div className="modal-content">
						{/* <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Modal title
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div> */}
						<div className="modal-body">
							<div className="cart-sidebar-wrapper">
								<div className="cart-content-wrapper">
									<h4>MY CART</h4>
								</div>
								<div className="cart-pro-wrapper">
									<div className="product-wrapper">
										<div className="product-img-wrapper">
											<figure>
												<img src={cart_img} />
											</figure>
										</div>
										<div className="product-content-wrapper">
											<h5>I AM A PRODUCT</h5>
											<h6>$20.00</h6>{" "}
											<div className="counter-wrapper">
												<div className="counter-btn-wrapper">
													<button className="plus" onClick={handleincrement}>
														+
													</button>
													<input value={counter} />
													<button onClick={handledecrement} className="minus">
														-
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="total-wrapper">
									<div className="total-price-wrapper">
										<h3>SUBTOTAL</h3>
										<h4>$80.00</h4>
										<button onClick={cartpage}>
											{" "}
											<img src={cart} /> View Cart
										</button>
									</div>
								</div>
							</div>
						</div>
						{/* <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
              <button type="button" className="btn btn-primary">
                Save changes
              </button>
            </div> */}
					</div>
				</div>
			</div>
		</>
	);
};

export default Product_page;
