// urls start here

export const base_url = `https://phplaravel-766278-2823818.cloudwaysapps.com/api`;
export const HomePageUrl = `${base_url}/home`;
export const AboutPageUrl = `${base_url}/about-us`;
export const RoadMapUrl = `${base_url}/road-maps`;
export const NewsLetterSubscribeUrl = `${base_url}/newsletter-subscribe`;
export const SchoolVideosUrl = `${base_url}/school-videos`;
export const NirvanaverseSignup = `${base_url}/nirvanaverse-signup`;
export const SiteSettingApiUrl = `${base_url}/site-settings`;

// headers starts here
export const headers = () => {
    return {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
    }
}

export const headersWithToken = (token) => {
    return {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
    }
}

export const headersWithImage = (token) => {
    return {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
            'Access-Control-Allow-Origin': '*'
        }
    }
}