import React from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import { creator_bg } from "../../constant/index";
import "../../assets/css/creator.css";

const MeetTheCreator = () => {
	return (
		<>
			<Header />
			{/* creator starts here */}
			<section className="creator-sec">
				<div className="container">
					<div className="row">
						<div className="col-lg-2"></div>
						<div className="col-lg-8">
							<div className="creator-content-wrapper">
								<div className="creator-heading-img-wraper">
									<figure>
										<img src={creator_bg} />
									</figure>
								</div>
								<div className="creator-conetent-wrapper">
									<h3>MEET THE CREATORS</h3>
									<h4>The inspiration for The Tanked Gil Schoolie</h4>
								</div>
							</div>
							<div className="creator-video-card-wrapper">
								<div className="video-wrapper">
									<iframe
										width="560"
										height="315"
										src="https://www.youtube.com/embed/xcJtL7QggTI"
										title="YouTube video player"
										frameBorder="0"
										allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
										allowFullScreen
									></iframe>
								</div>
								<div className="video-wrapper">
									<iframe
										width="560"
										height="315"
										src="https://www.youtube.com/embed/xcJtL7QggTI"
										title="YouTube video player"
										frameBorder="0"
										allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
										allowFullScreen
									></iframe>
								</div>
								<div className="video-wrapper">
									<iframe
										width="560"
										height="315"
										src="https://www.youtube.com/embed/xcJtL7QggTI"
										title="YouTube video player"
										frameBorder="0"
										allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
										allowFullScreen
									></iframe>
								</div>
							</div>
						</div>
						<div className="col-lg-2"></div>
					</div>
				</div>
				<Footer />
			</section>
			{/* creator ends here */}
		</>
	);
};

export default MeetTheCreator;
