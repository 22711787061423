import PublicRoutes from "./routes/PublicRoutes";
import "../src/assets/css/style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";
import { useEffect } from "react";
import { dripe_mobile, drive_mobile, footer_mobile } from "./constant";
import $ from "jquery";
import { ToastContainer , toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Unity, { UnityContext } from "react-unity-webgl";

const unityContext = new UnityContext({


  // loaderUrl: "/Build/ComicVerse_v2.loader.js",
  // dataUrl: "/Build/ComicVerse_v2.data.unityweb",
  // frameworkUrl: "/Build/ComicVerse_v2.framework.js.unityweb",
  // codeUrl: "/Build/ComicVerse_v2.wasm.unityweb",

  loaderUrl: "/Build/ClassClown_v4.loader.js",
  dataUrl: "/Build/ClassClown_v4.data",
  frameworkUrl: "/Build/ClassClown_v4.framework.js",
  codeUrl: "/Build/ClassClown_v4.wasm",
  

  // loaderUrl: "/Build/ComicVerse_v3.loader.js",
  // dataUrl: "/Build/ComicVerse_v3.data",
  // frameworkUrl: "/Build/ComicVerse_v3.framework.js",
  // codeUrl: "/Build/ComicVerse_v3.wasm",
  // streamingAssetsUrl: "/AgoraWebSDK/agorartcenginev2.js",

  


  // loaderUrl: "/Build/CSHWebGLPackage.loader.js",
  // dataUrl: "/Build/CSHWebGLPackage.data",
  // frameworkUrl: "/Build/CSHWebGLPackage.framework.js",
  // codeUrl: "/Build/CSHWebGLPackage.wasm",

  // loaderUrl: "/Build/CSHWebGLPackage2.loader.js",
  // dataUrl: "/Build/CSHWebGLPackage2.data",
  // frameworkUrl: "/Build/CSHWebGLPackage2.framework.js",
  // codeUrl: "/Build/CSHWebGLPackage2.wasm",
  // webGLContextAttributes: {
  //   failIfMajorPerformanceCaveat: true,
  //   powerPreference: "high-performance",
  // },
});

function App() {
  useEffect(() => {
    if (window.innerWidth <= "480") {
      $(".footer-card-wrapper .footer-img-wrapper img").attr(
        "src",
        footer_mobile
      );
      $(".sec-about .about-card-wrapper .about-img-wrapper img").attr(
        "src",
        drive_mobile
      );
    }
  }, []);


  return (
    <>
      <div id="loader-full" className="loader-full">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
      <ToastContainer  />
      <PublicRoutes 
        unityContext={unityContext}
      />
    </>
  );
}

export default App;
