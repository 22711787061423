import React, { useEffect, useState } from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import { about_page_card_img, about_page_mobile } from "../../constant/index";
import "../../assets/css/about.css";
import $ from "jquery";
import { AboutPageUrl, headers } from "../../Api helpers/ApiHelper";
import axios from "axios";
import { showLoader } from "../../constant/Loader";

const About = () => {

	const [state, setState] = useState();

	useEffect(() => {
		if (window.innerWidth < "480") {
			$(".ab .about-card-wrapper img").attr("src", about_page_mobile);
		}
	}, []);


	const AboutUsApi = async () => {
		showLoader("flex");
		try {
			const { data } = await axios.get(AboutPageUrl, headers)
			// console.log(data.response.data);
			setState(data.response.data);
			showLoader("none");
		} catch (error) {
			console.log(error);
			showLoader("none");
		}
	}

	useEffect(() => {
		AboutUsApi();
		window.scroll(0,0);
	}, [])


	return (
		<>
			<Header />
			{/* About starts here */}
			<section className="about-page-sec ab">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="about-card-wrapper">
								<div className="about-img-wrapper">
									<figure>
										<img src={about_page_card_img} className="img-fluid" />
									</figure>
								</div>
								<div className="about-content-wrapper">
									<h3>{state?.headingOne}</h3>
									<h3>{state?.headingTwo}</h3>
									<div
										dangerouslySetInnerHTML={{ __html: state?.content }} >
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</section>
			{/* About ends here */}
		</>
	);
};

export default About;
